import React, { useEffect, useState } from 'react';
import {Link, NavLink} from 'react-router-dom';
import DonutChart from './Dashboard/DonutChart';
import { UseAPI } from '../../../services/UseAPI';
import banner from "../../../images/milad16_logo.jpg";
import { Button } from 'react-bootstrap';

const Home = () => {
	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(true);
	const user = JSON.parse(localStorage.getItem('userDetails'));
	//console.log(user);
	useEffect(() => {
		UseAPI(`peserta/${user.kode_peserta}/${user.nama_kegiatan}`, {}, 'GET')
			.then((resp) => {
				setData(resp.data);
				setLoading(false);
				//console.log("resp", resp);
			})
	}, []);

	return (
		<>
			<div style={{ textAlign: 'center' }}>
				<img src={banner} alt="User" style={{ width: '90%', height: '90%', borderRadius: '2%' }} />
				<NavLink to="/pemenang" className="btn btn-primary mt-3">Daftar Pemenang Undian</NavLink>	
				<div className="card" style={{ margin: '16px', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
					<div className="card-body">
						{loading ? (
							<div className="d-flex justify-content-center">
								<div className="spinner-border" role="status">
									<span className="sr-only">Loading...</span>
								</div>
							</div>
						) : (
							<>
								<h3 className="card-title fs-20">{data.nama_peserta}</h3>
								<p className="card-text fs-20" style={{lineHeight:0.5}}>{data.kode_peserta}<br /></p>
								<p className="card-text" style={{fontFamily:'fantasy',letterSpacing:10, fontSize:30,lineHeight: '45px'}}>{data.rfid}</p>
								{
									data.juara !== 0 && (
										<>
											<hr  className='mt-0 mb-3'/>
											{/* <p className="card-text" style={{fontFamily:'fantasy',letterSpacing:1, fontSize:20, lineHeight:'10px'}}>{data.keterangan}</p> */}
										</>
									)
								}
							</>
						)}
					</div>
				</div>
			</div>
		</>
	);
}
export default Home;