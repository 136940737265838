import React from "react";

import { Link } from "react-router-dom";
import Swal from "sweetalert2";

const Header = ({ onNote }) => {
  var path = window.location.pathname.split("/");
  var name = path[path.length - 1].split("_");
  var filterName = path.length >= 3 ? path.filter((n, i) => i > 1) : name;
  var finalName = filterName;
  //console.log('finalName',finalName);
  if (Number.isInteger(parseInt(finalName[finalName.length-1]))) {
    //console.log('nomor');
    finalName = finalName[0];
  } else {
    //console.log('huruf');

  }
  const signOut = () => {
    Swal.fire({
      title: 'Anda yakin?',
      text: "Anda akan keluar dari aplikasi !",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya, keluar!',
      cancelButtonText: 'Batal'
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.clear();
        window.location.href="/"
      }
    })
  }
  //console.log(finalName);
  return ( 
    <div className="header border-bottom">
      <div className="header-content m-0 p-0">
        <nav className="navbar navbar-expand">
          <div className="collapse navbar-collapse justify-content-between">
            <div className="navbar-nav">
              <div className="dashboard_bar" style={{ textTransform: "capitalize" }}>
                MILAD 61 - UNISMUH MKS
              </div>
            </div>
            <ul className="navbar-nav px-3">
              <li className="nav-item">
                <Link to="#" onClick={()=>signOut()} className="btn btn-primary"><i className="fas fa-sign-out-alt"></i> Keluar</Link>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Header;
