export function UseAPI(type, userData, method) {
    // console.log(type);
    // let BaseURL = 'http://10.10.2.132/event_api/';
    let BaseURL = 'https://apps.unismuh.ac.id/event_api/';
    return new Promise((resolve, reject) =>{    
        switch (method) {            
            case 'POST':
                var formdata = new FormData();
                Object.entries(userData).map(([key, value]) =>
                    formdata.append(key,value)
                );                
                // formdata.append('idToken',(localStorage.getItem('userDetails')));
                
                var requestOptions = {
                    method: 'POST',
                    body: formdata,
                    redirect: 'follow',
                    
                };

                if (type !== 'login-member') {
                    requestOptions.headers = {token:JSON.parse(localStorage['userDetails']).idToken,session_time:JSON.parse(localStorage['userDetails']).waktu??=''}
                }

                if (type === 'login-member' || type === 'daftar-member' || type === 'pass_code_general/registration' || type === 'pass_code_general/confirm' || type === 'daftar-peserta') {
                    // console.log("pass_code_general/registration");
                    fetch(BaseURL+type, requestOptions)
                    .then(response => response.json())
                    .then(result => resolve(result))
                    .catch(error => reject(error));
                } else {
                    requestOptions.headers = {token:JSON.parse(localStorage['userDetails']).idToken}
                    fetch(BaseURL+type, requestOptions)
                    .then(response => response.json())
                    .then(result => resolve(result))
                    .catch(error => reject(error));    
                }
                
                break;        
            case 'DELETE':
                var urlencoded = new URLSearchParams();
                var requestOptions = {
                    method: 'DELETE',
                    body: urlencoded,
                    redirect: 'follow',
                };

                if (type !== 'login-member') {
                    requestOptions.headers = {token:JSON.parse(localStorage['userDetails']).idToken,session_time:JSON.parse(localStorage['userDetails']).waktu??=''}
                }
                
                fetch(BaseURL+type, requestOptions)
                .then(response => response.json())
                .then(result => resolve(result))
                .catch(error => reject(error));
                break;        
            case 'GET':
                if (type === 'login-member' || type === 'daftar-member') {
                    fetch(BaseURL+type, {
                        method: 'GET',    
                    })
                    .then((response) => response.json())
                    .then((res) => {
                        resolve(res);
                    })
                    .catch((error) => {
                        reject(error);
                    });                        
    
                } else {
                    fetch(BaseURL+type, {
                        method: 'GET',
                        headers: {
                            token:JSON.parse(localStorage['userDetails']).idToken??='',
                            sessiontime:JSON.parse(localStorage['userDetails']).waktu??=''

                        }
    
                    })
                    .then((response) => response.json())
                    .then((res) => {
                        resolve(res);
                    })
                    .catch((error) => {
                        reject(error);
                    });                           
                }

                break;
        }
    });
}