import React, { Fragment, useContext, useState } from "react";
/// React router dom
import { Link } from "react-router-dom";
import { ThemeContext } from "../../../context/ThemeContext";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle, faSailboat, faSquare, faNewspaper, faUser } from '@fortawesome/free-solid-svg-icons'

const NavHader = () => {
  const [toggle, setToggle] = useState(false);
  const { navigationHader, openMenuToggle, background } = useContext(
    ThemeContext
  );
  return (
    <div className="nav-header d-flex justify-content-center p-3">
        <button className="btn btn-primary">
            <FontAwesomeIcon icon={faUser} />
        </button>
    </div>
  );
};

export default NavHader;
