import { faArrowLeft, faRefresh } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useState } from "react"
import { Button, NavLink } from "react-bootstrap"
import { Router, useHistory } from "react-router-dom"
import { UseAPI } from "../../services/UseAPI"

const Pemenang = () => {
    const navigate = useHistory();
	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(true);
	const user = JSON.parse(localStorage.getItem('userDetails'));

    // const pemenang = await UseAPI(`pemenang/${user.nama_kegiatan}`, {}, 'GET');

	useEffect(async () => {
		await UseAPI(`pemenang/${user.nama_kegiatan}`, {}, 'GET')
			.then((resp) => {
                console.log(resp);
				setData(resp.data);
				setLoading(false);
			})
	}, []);

    // const pemenang = await UseAPI(`pemenang/${user.nama_kegiatan}`, {}, 'GET');
    return (
        <>
            <div className="d-flex justify-content-between">
                <NavLink className="btn btn-secondary" onClick={() => navigate.goBack()}><FontAwesomeIcon icon={faArrowLeft} /></NavLink>
                {/* <NavLink className="btn btn-secondary" to="/pemenang" onClick={() => navigate.push("/pemenang")}><FontAwesomeIcon icon={faRefresh} /></NavLink> */}
                {/* <Button variant="secondary" onClick={() => window.location.reload()}><FontAwesomeIcon icon={faRefresh} /></Button> */}
                <h2>Pemenang Undian</h2>
            </div>
            <div className="mt-4">
                <div className="card">
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                            <div className="d-flex justify-content-between">
                                <div className="d-flex flex-row align-items-center">
                                    <div className="ms-0">
                                        <div className="fw-bold">PESERTA</div>
                                    </div>
                                </div>
                                <div className="d-flex flex-row align-items-center">
                                    <div className="ms-0">
                                        <div className="fw-bold">UNDIAN</div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        {loading ? (
                            <li className="list-group-item">
                                <div className="text-center">
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div>
                            </li>
                        ) : data && data.length > 0 ? (
                            data.map((item, key) => (
                                <li className="list-group-item" key={key}>
                                    <div className="d-flex justify-content-between">
                                        <div className="d-flex flex-row align-items-center">
                                            <div className="ms-0">
                                                <div className="fw-bold">{item.rfid}<br />{item.nama_peserta}<br />{'XXXXXX' + item.kode_peserta.slice(6)}</div>
                                            </div>
                                        </div>
                                        <div className="d-flex flex-row align-items-center">
                                            <div className="ms-0">
                                                <div className="fw-bold">{item.keterangan}</div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            ))
                        ) : (
                            <li className="list-group-item">
                                <div className="text-center">No data available</div>
                            </li>
                        )}
                    </ul>
                </div>
            </div>
        </>
    )
}
export default Pemenang